import "./App.css";

import Landing from "./components/HeroLanding";
import AboutMe from "./components/About.js";
import Career from "./components/Career.js";
import Skills from "./components/Skills.js";
import Education from "./components/Education.js";
import Contact from "./components/ContactMe.js";
import CV from "./components/CV.js";
import NavBAr from "./components/NavBar";
import { useState } from "react";

function App() {
  const [particleLoading, setLoading] = useState(true);

  const loadPage = () => {
    setLoading(false);
  };

  return (
    <div className="App">
      <Landing particleLoading={particleLoading} loadPage={loadPage} />
      {!particleLoading && (
        <>
          <NavBAr />
          <AboutMe />
          <Skills />
          <Career />
          <CV />
          <Education />
          <Contact />
        </>
      )}
    </div>
  );
}

export default App;
