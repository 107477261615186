import profilePic from "../images/about_pic.jpeg";
import { FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
const links = {
  linkedIn: "https://www.linkedin.com/in/chrishonne-williams-92a088a3/",
  instagram: "https://www.instagram.com/_chrishonne/",
  email: "#contact_form",
};

const AboutMe = () => {
  return (
    <span id="about_me">
      <div id="container">
        <div id="about_Me">
          <div id="aboutMe_imageContainer">
            <img id="aboutMe_Img" src={profilePic} alt="" />
          </div>
          <div
            id="aboutMe_title"
            style={{ paddingBottom: "20px", gridArea: "title" }}
          >
            <h2 style={{ margin: "0" }}>ABOUT ME</h2>
            <h4 style={{ fontWeight: "300", margin: "0" }}>
              Software Developer | UI/UX Designer | Electronics Engineer
            </h4>
          </div>
          <div id="aboutMe_text_content">
            <div id="aboutMe_ul_div">
              <ul id="aboutMe_ul">
                <li>
                  Name: <span>Chrishonne Williams</span>
                </li>
                <li>
                  Age: <span>{calcAge()}</span>
                </li>
                <li>
                  City: <span>Kingston, JM</span>
                </li>
                <li>
                  Tel: <span>+1 (876) 508 6443</span>
                </li>
              </ul>
              <ul id="aboutMe_ul">
                <li>
                  Degree: <span>Bsc. Electronics Engineering</span>
                </li>
                <li>
                  Website: <span>ChrishonneWilliams.com</span>
                </li>
                <li>
                  Freelance: <span>Available</span>
                </li>
                <li>
                  <div id="social_icons">
                    Social:
                    <a
                      href={links.linkedIn}
                      alt="Link-in Profile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin size={30} />
                    </a>
                    <a
                      href={links.email}
                      alt="Send an Email"
                      rel="noopener noreferrer"
                    >
                      <MdEmail size={30} />
                    </a>
                    <a
                      href={links.instagram}
                      alt="Instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiFillInstagram size={30} />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};

const calcAge = () => {
  const today = new Date();
  const birthDate = new Date("1995-09-01");
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default AboutMe;
