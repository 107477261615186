import sms_logo from "../images/sms_svg.svg";
import digi_logo from "../images/digicel_logo_1.png";
import jbg_logo from "../images/jbg_svg.svg";

const career = () => {
  return (
    <div id="container">
      <div id="careerContainer">
        <h2 style={{ textAlign: "center" }}>CAREER</h2>
        <ul id="career-ul" style={{ listStyleType: "none" }}>
          <li>{career_li("jbg")}</li>
          <li style={{ marginTop: "30px" }}>{career_li("sms")}</li>
          <li style={{ marginTop: "30px" }}>{career_li("digicel")}</li>
        </ul>
      </div>
    </div>
  );
};

export default career;

const career_li = (x) => {
  if (x === "sms") {
    return (
      <div id="career-li">
        <span id="career-li-company">
          <img src={sms_logo} alt="sms logo" style={{ width: "100%" }} />
          <p id="job-title">SOFTWARE DEVELOPER</p>
          <p>04/2020 - 05/2022</p>
        </span>
        <span id="career-li-dets">
          <p>
            As a software developer at SMS I've worked on projects both
            internally and externally.
            <br />
            Externally, I consult for NCB as part of a lab where my primary role
            is that of a backend developer and as such I often design, update,
            maintain and refactor middlewares and APIs governed by the scrum
            team. During my tenure at NCB, my scrum designed, developed and
            deployed "NCB Assist".
            <br />
            Internally, I was tasked with testing and updating SMS' payment
            gateway to facilitate its integration into 3rd party applications.
          </p>
        </span>
      </div>
    );
  } else if (x === "digicel") {
    return (
      <div id="career-li">
        <span id="career-li-company">
          <img src={digi_logo} alt="digicel logo" style={{ width: "100%" }} />
          <p id="job-title">
            SERVICE LAYER, MEDIATION AND APPLICATION SYSTEMS ENGINEER
          </p>
          <p>08/2019 - 05/2020</p>
        </span>
        <span id="career-li-dets">
          <p>
            As the incumbent I participated in the design, development and
            implementation of a number solutions to improve system performance
            and network resilience across multiple systems. I had written
            scripts to create new functionality and automate existing processes
            to make them more efficient. This effort required the analysis of
            the systems' load parameters and led to recommendations for system
            optimization, expansion and upgrade; several of which were actioned.
            Also, I've managed changes to the network for maintenance, repair,
            expansion, upgrade or service setup. I often prepared reports;
            management, incident and others, as required and made the subsequent
            presentations to the assigned senior engineer, manager or the team
            repectively.
          </p>
        </span>
      </div>
    );
  } else if (x === "jbg") {
    return (
      <div id="career-li">
        <span id="career-li-company">
          <img
            src={jbg_logo}
            alt="Jamaica Broilers Logo"
            style={{ width: "100%" }}
          />
          <p id="job-title">PROGRAMMER</p>
          <p>05/2022 - PRESENT</p>
        </span>
        <span id="career-li-dets">
          <p>
            I successfully designed and developed a comprehensive React Native
            application, enhancing data capture, management, and storage across
            various workflows and processes. This innovation significantly
            reduced operational costs by eliminating the need for paper, ink,
            printers, and physical storage, saving the company millions.
            Additionally, the app allows real-time tracking and analysis of key
            data metrics, which improved operational efficiency and
            decision-making. I have been actively involved in integrating D365
            into our current software solution, developing several reports using
            X++ and C# to enhance system functionality and user experience.
            Furthermore, I successfully set up multiple computers to integrate
            seamlessly with hardware like scales through serial connections and
            automated database updates through the development of scripts and
            APIs, enhancing system efficiency and accuracy.
          </p>
        </span>
      </div>
    );
  }
};
