import resume from "../files/ChrishonneW_Resume_.pdf";
import { BsDownload } from "react-icons/bs";

const CV = () => {
  return (
    <div id="CV_container">
      <div id="CV_subcontainer">
        <div style={{ display: "grid", justifyContent: "space-between" }}>
          <h2 style={{ verticalAlign: "text-top" }}>
            Would you like more info?
          </h2>
          <p style={{ lineHeight: "1", paddingBottom: "5px" }}>
            Download a copy of my Resume
          </p>
        </div>
        <div id="button_container">
          <a
            href={resume}
            alt="Resume"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <button>
              <BsDownload id="download-icon" size={30} />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CV;
