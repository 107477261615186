import uwi_logo from "../images/uwi_logo.svg";
import fcc_logo from "../images/fcc_logo_dark.svg";

const certs = [
  {
    name: "Bsc. Electronics Engineering",
    year: "2019",
    logo: "uwi",
  },
  {
    name: "Certified Responsive Web Developer",
    year: "2021",
    logo: "fcc",
  },
  {
    name: "JavaScript Algorithms and Data Structures",
    year: "2021",
    logo: "fcc",
  },
  {
    name: "Front End Development Libraries",
    year: "2022",
    logo: "fcc",
  },
];

const education = () => {
  return (
    <span id="education">
      <div id="container">
        <h2>EDUCATION</h2>
        <div id="education_div" style={{ width: "105%", marginLeft: "-5%" }}>
          {certs &&
            certs.map((cert, idx) => <CertTile cert={cert} key={idx} />)}
        </div>
      </div>
    </span>
  );
};

export default education;

// const educationTile = (x) => {
//   if (x === "uwi") {
//     return (
//       <div id="education_tile">
//         <div id="educationTile_imgDiv">
//           <img src={uwi_logo} alt="" style={{ width: "50%" }} />
//         </div>
//         <div id="educationTile_content">
//           <p style={{ textAlign: "center" }}>
//             <i>2019</i> - Bsc. Electronics Engineering
//           </p>
//         </div>
//       </div>
//     );
//   } else if (x === "fcc") {
//     return (
//       <div id="education_tile">
//         <div id="educationTile_imgDiv">
//           <img src={fcc_logo} alt="" style={{ width: "50%" }} />
//         </div>
//         <div id="content">
//           <p style={{ textAlign: "center" }}>
//             <i>2021</i> - Certified Responsive Web Developer
//           </p>
//         </div>
//       </div>
//     );
//   } else if (x === "fcc1") {
//     return (
//       <div id="education_tile">
//         <div id="educationTile_imgDiv">
//           <img src={fcc_logo} alt="" style={{ width: "50%" }} />
//         </div>
//         <div id="content">
//           <p style={{ textAlign: "center" }}>
//             <i>2021</i> - JavaScript Algorithms and Data Structures
//           </p>
//         </div>
//       </div>
//     );
//   }
// };

const CertTile = ({ cert }) => {
  const { name, year, logo } = cert;
  const logoPath = {
    fcc: fcc_logo,
    uwi: uwi_logo,
  };
  return (
    <div id="education_tile">
      <div id="educationTile_imgDiv">
        <img src={logoPath[logo]} alt="" style={{ width: "50%" }} />
      </div>
      <div id="content">
        <p style={{ textAlign: "center" }}>
          <i>{year}</i> - {name}
        </p>
      </div>
    </div>
  );
};

//`As a software developer I've been assigned to several projects during my tenure; in the capacity of either a UI/UX designer, Frontend Developer or in most cases, a Backend Developer. My most recent project is NCB Assist which was recently launched to production.`
//`In the capacity of this position I found myself working on systems that govern the Prepaid products. With that, maintenance checks, optimizations and file management were common place. Most of my work was done on Unix systems and often took the form of automated bash scripts which interacted with multiple servers and APIs.`
