import { BiArrowToTop } from "react-icons/bi";

const NavBAr= () => {
    return (
        <div id="NavBar">
            <a style={{textAlign:"center"}}href="#HeroContainer"><BiArrowToTop size={30}/></a>
            <a href="#about_me">About Me</a>
            <a href="#careerContainer">Career</a>
            <a href="#education">Education</a>
            <a href="#contact_form">Contact Me</a>
        </div>
    );
}

export default NavBAr;