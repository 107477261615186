//TO DO: Create skillItem function that takes name and percentage as arguments and returns item !!!!
const skillsArr = [
  {
    name: "React Native",
    skillLevel: "90%",
  },
  {
    name: "React.js",
    skillLevel: "85%",
  },
  {
    name: "JavaScript",
    skillLevel: "85%",
  },
  {
    name: "CSS",
    skillLevel: "85%",
  },
  {
    name: "Node.js",
    skillLevel: "80%",
  },
  {
    name: "HTML",
    skillLevel: "80%",
  },
  {
    name: "Java",
    skillLevel: "75%",
  },
  {
    name: "Git",
    skillLevel: "75%",
  },
];
const ad_skills = [
  "APIs",
  "C",
  "C#",
  "Python",
  "MongoDB",
  "Figma",
  "SQL",
  "Firebase",
  "Arduino C",
  "JSON",
  "XML",
  "ASP.NET",
  "Azure",
  "Spring Framework",
  "IoT Development",
  "Schematic design",
  "Web Scraping",
  "Agile Framework (Scrum)",
  "Critical Thinking",
];

const skills = () => {
  return (
    <div id="container">
      <h2>MY SKILLS</h2>
      <div id="skills_list">
        {skillsArr.map((skl, idx) => (
          <SkillPercent skill={skillsArr[idx]} key={idx} />
        ))}
      </div>
      <div id="additional-skills-container">
        <h3>ADDITIONAL SKILLS</h3>
        <ul>
          {ad_skills.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default skills;

const SkillPercent = ({ skill }) => {
  const { name, skillLevel } = skill;
  return (
    <div id="skills_item">
      <span id="skillText_span">
        <p>{name}</p>
        <p>{skillLevel}</p>
      </span>
      <div className="bar" id="loadingBar">
        <span style={{ width: skillLevel }}></span>
      </div>
    </div>
  );
};
