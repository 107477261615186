import { useState } from "react";
import { send } from "emailjs-com";
import toast, { Toaster } from "react-hot-toast";

function Contact() {
  const cancelCourse = () => {
    document.getElementById("contact_form").reset();
  };

  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const emailToast = toast.loading("Sending email ...");
    /* --- METHOD TO SEND THE MAIL --- */
    send(
      "service_qcujsbq",
      "template_1c6suge",
      toSend,
      "user_JEyQeGEfi6SksOPYudJU1"
    )
      .then((response) => {
        ///console.log(response);
        toast.success("Your Email Has been Sent !", {
          id: emailToast,
        });
        cancelCourse();
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Your email was not been sent, please try again later !!!",
          {
            id: emailToast,
          }
        );
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div id="container">
      <Toaster position="top-center" reverseOrder={false} />
      <h2>GET IN TOUCH</h2>
      <form id="contact_form" onSubmit={onSubmit}>
        <input
          name="from_name"
          style={{ gridArea: "name" }}
          id="form_name"
          type="text"
          placeholder="Your Name*"
          onChange={handleChange}
          required
        ></input>
        <input
          name="reply_to"
          style={{ gridArea: "email" }}
          id="form_email"
          type="email"
          placeholder="Your Email*"
          onChange={handleChange}
          required
        ></input>
        <input
          name="to_name"
          style={{ gridArea: "subject" }}
          id="form_subject"
          type="text"
          placeholder="Your Subject*"
          onChange={handleChange}
        ></input>
        <textarea
          name="message"
          style={{ gridArea: "message" }}
          id="form_message"
          type="text-area"
          placeholder="Your Message ..."
          onChange={handleChange}
          required
        ></textarea>
        <input id="form_submit" type="submit" value="Send Message"></input>
      </form>
    </div>
  );
}

export default Contact;
