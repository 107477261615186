// import Particles from 'tsparticles';
// import particlesConfig from '../config/configParticles.js';
import ParticlesComp from "./Particles";
const heroLanding = ({ particleLoading, loadPage }) => {
  console.log(particleLoading);
  return (
    <div id="HeroContainer">
      {/* <Particles  className="Particles" params={particlesConfig}/> */}
      <ParticlesComp loadPage={loadPage} />
      {particleLoading ? (
        <div>loading ... </div>
      ) : (
        <div id="heroText">
          <h3 style={{ color: "#ffff", margin: "0", fontWeight: "100" }}>
            Hi, I'm
          </h3>
          <h1 style={{ color: "#dbb563", margin: "0" }}>CHRISHONNE WILLIAMS</h1>
          <p style={{ color: "#ffff", margin: "0", fontWeight: "100" }}>
            <span>- Software Developer</span> | <span>UI/UX Designer</span> |{" "}
            <span>Engineer -</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default heroLanding;
